export const Resources = {
  "lang": "lang",
  "min": "min",
  "1st": "1st",
  "2nd": "2nd",
  "3rd": "3rd",
  "4th": "4th",
  "5th": "5th",
  "6th": "6th",
  "7th": "7th",
  "date": "date",
  "date__today": "date__today",
  "date__tomorrow": "date__tomorrow",
  "date__yesterday": "date__yesterday",
  "time__4H": "time__4H",
  "time__12H": "time__12H",
  "time__24H": "time__24H",
  "sport__sports": "sport__sports",
  "sport__afl": "sport__afl",
  "sport__mma": "sport__mma",
  "sport__hockey": "sport__hockey",
  "sport__soccer": "sport__soccer",
  "sport__tennis": "sport__tennis",
  "sport__boxing": "sport__boxing",
  "sport__esports": "sport__esports",
  "sport__cricket": "sport__cricket",
  "sport__baseball": "sport__baseball",
  "sport__football": "sport__football",
  "sport__basketball": "sport__basketball",
  "sport__volleyball": "sport__volleyball",
  "sport__boxing_mma": "sport__boxing_mma",
  "sport__rugby_union": "sport__rugby_union",
  "sport__table_tennis": "sport__table_tennis",
  "sport__rugby_league": "sport__rugby_league",
  "filter__all": "filter__all",
  "filter__search_event": "filter__search_event",
  "fillter__sort_by": "fillter__sort_by",
  "filter__clear_all": "filter__clear_all",
  "filter_bet__all_bets": "filter_bet__all_bets",
  "filter_bet__search_bet": "filter_bet__search_bet",
  "filter_bet__completed": "filter_bet__completed",
  "filter_bet__cancelled": "filter_bet__cancelled",
  "filter_bet__pending": "filter_bet__pending",
  "filter_game__search_bet": "filter_game__search_bet",
  "event_message__no_events": "event_message__no_events",
  "event_message__waiting_for_odds": "event_message__waiting_for_odds",
  "event__match": "event__match",
  "event__games": "event__games",
  "event__rounds": "event__rounds",
  "event__1x2": "event__1x2",
  "event__h_a": "event__h_a",
  "event__handicap": "event__handicap",
  "event__total": "event__total",
  "event__over": "event__over",
  "event__under": "event__under",
  "event__odd": "event__odd",
  "event__even": "event__even",
  "event__winner": "event__winner",
  "event__result": "event__result",
  "event__draw": "event__draw",
  "event__run_line": "event__run_line",
  "event__team_total": "event__team_total",
  "event__double_chance": "event__double_chance",
  "event__both_teams_to_score": "event__both_teams_to_score",
  "event__clean_sheet": "event__clean_sheet",
  "event__ml": "event__ml",
  "event__not_available": "event__not_available",
  "event__live": "event__live",
  "event__finished": "event__finished",
  "event__results_disclaimer": "event__results_disclaimer",
  "event__to_qualify": "event__to_qualify",
  "event__extra_time": "event__extra_time",
  "event__penalties": "event__penalties",
  "event__points": "event__points",
  "Points": "Points",
  "event__correct_score": "event__correct_score",
  "event__1st_period": "event__1st_period",
  "event__2nd_period": "event__2nd_period",
  "event__3rd_period": "event__3rd_period",
  "event__4th_period": "event__4th_period",
  "event__5th_period": "event__5th_period",
  "event__6th_period": "event__6th_period",
  "event__7th_period": "event__7th_period",
  "event__8th_period": "event__8th_period",
  "event__9th_period": "event__9th_period",
  "event__draw_no_bet": "event__draw_no_bet",
  "event__1st_map": "event__1st_map",
  "event__2nd_map": "event__2nd_map",
  "event__3rd_map": "event__3rd_map",
  "event__4th_map": "event__4th_map",
  "event__5th_map": "event__5th_map",
  "event__6th_map": "event__6th_map",
  "event__7th_map": "event__7th_map",
  "event__1st_game": "event__1st_game",
  "event__2nd_game": "event__2nd_game",
  "event__3rd_game": "event__3rd_game",
  "event__4th_game": "event__4th_game",
  "event__5th_game": "event__5th_game",
  "event__6th_game": "event__6th_game",
  "event__7th_game": "event__7th_game",
  "event__log": "event__log",
  "event__scores": "event__scores",
  "event__sets": "event__sets",
  "event__starts_in": "event__starts_in",
  "event__no_events_scheduled": "event__no_events_scheduled",
  "event__explore_other": "event__explore_other",
  "event_stream__please_sign_in": "event_stream__please_sign_in",
  "event_stream__not_available": "event_stream__not_available",
  "event_stream": "event_stream",
  "casino": "casino",
  "casino__all_games": "casino__all_games",
  "casino__favourites": "casino__favourites",
  "casino__recent": "casino__recent",
  "casino__search": "casino__search",
  "casino__provider": "casino__provider",
  "casino__recently_added": "casino__recently_added",
  "casino__real_play": "casino__real_play",
  "casino__fun_play": "casino__fun_play",
  "casino__low_balance": "casino__low_balance",
  "casino__currency_not_supported": "casino__currency_not_supported",
  "casino__balance_show": "casino__balance_show",
  "casino__history": "casino__history",
  "casino__no_games": "casino__no_games",
  "casino__load_more": "casino__load_more",
  "casino__top_up": "casino__top_up",
  "casino__ip_restriction": "casino__ip_restriction",
  "casino__cant_play_bonus": "casino__cant_play_bonus",
  "casino__vpn_services": "casino__vpn_services",
  "casino__displaying": "casino__displaying",
  "casino__game_ended": "casino__game_ended",
  "casino__game_failed": "casino__game_failed",
  "casino__game_active": "casino__game_active",
  "casino__game_with_crypto": "casino__game_with_crypto",
  "casino__game_providers": "casino__game_providers",
  "casino__game_slots": "casino__game_slots",
  "casino__game_roulette": "casino__game_roulette",
  "casino__game_instant": "casino__game_instant",
  "casino__game_live casino": "casino__game_live casino",
  "casino__game_table games": "casino__game_table games",
  "casino__game_provably fair": "casino__game_provably fair",
  "casino__game_blackjack": "casino__game_blackjack",
  "casino__game_baccarat": "casino__game_baccarat",
  "casino__game_poker": "casino__game_poker",
  "casino__game_dice": "casino__game_dice",
  "casino__game_keno": "casino__game_keno",
  "casino__game_plinko": "casino__game_plinko",
  "casino__game_lobby": "casino__game_lobby",
  "casino__game_all games": "casino__game_all games",
  "casino__game_favourites": "casino__game_favourites",
  "casino__game_recent": "casino__game_recent",
  "casino__game_to_start": "casino__game_to_start",
  "casino__game_restricted_geo_title": "casino__game_restricted_geo_title",
  "casino__game_restricted_geo_subtitle": "casino__game_restricted_geo_subtitle",
  "casino__game_restricted_geo_link": "casino__game_restricted_geo_link",
  "casino__game_popup_title": "casino__game_popup_title",
  "casino__game_popup_message": "casino__game_popup_message",
  "casino__game_popup_message_secondary": "casino__game_popup_message_secondary",
  "dividends__title": "dividends__title",
  "dividents__description": "dividents__description",
  "dividends__pool": "dividends__pool",
  "dividends__total_staked": "dividends__total_staked",
  "dividends__claim": "dividends__claim",
  "dividends__claim_dividends": "dividends__claim_dividends",
  "dividends__estimated_payout": "dividends__estimated_payout",
  "dividends__last_payout": "dividends__last_payout",
  "dividends__stake": "dividends__stake",
  "dividends__unstake": "dividends__unstake",
  "dividends__weekly_stats": "dividends__weekly_stats",
  "dividends__you_staked": "dividends__you_staked",
  "dividends__you_have": "dividends__you_have",
  "dividends__stake_amount": "dividends__stake_amount",
  "dividends__unstake_amount": "dividends__unstake_amount",
  "dividends__max_stake": "dividends__max_stake",
  "dividends_error__positive_amount": "dividends_error__positive_amount",
  "home__title": "home__title",
  "auth__sign_in": "auth__sign_in",
  "auth__sign_up": "auth__sign_up",
  "auth__sign_up_2": "auth__sign_up_2",
  "auth__signing_in": "auth__signing_in",
  "auth__logout": "auth__logout",
  "auth__login_method": "auth__login_method",
  "auth_message__not_logged_in": "auth_message__not_logged_in",
  "auth__license_agreement": "auth__license_agreement",
  "auth__account_creation": "auth__account_creation",
  "auth__congratulations": "auth__congratulations",
  "auth__wrong_password": "auth__wrong_password",
  "auth__forgot_password": "auth__forgot_password",
  "auth__email": "auth__email",
  "auth__password": "auth__password",
  "auth__welcome_back": "auth__welcome_back",
  "auth__dont_have_account": "auth__dont_have_account",
  "auth__create_account": "auth__create_account",
  "auth__account_created": "auth__account_created",
  "auth__change_password": "auth__change_password",
  "auth__reset_password": "auth__reset_password",
  "auth__password_changed": "auth__password_changed",
  "auth__current_password": "auth__current_password",
  "auth__new_password": "auth__new_password",
  "auth__reset_password_instructions": "auth__reset_password_instructions",
  "auth__signin_to_use": "auth__signin_to_use",
  "auth__tcs_apply": "auth__tcs_apply",
  "auth__activated": "auth__activated",
  "auth__activated_2": "auth__activated_2",
  "auth__terms_privacy": "auth__terms_privacy",
  "auth__join_now": "auth__join_now",
  "auth__join_with": "auth__join_with",
  "auth__sign_with": "auth__sign_with",
  "auth__account_exists": "auth__account_exists",
  "auth__sign_in_to_continue": "auth__sign_in_to_continue",
  "auth__join_with_bonus": "auth__join_with_bonus",
  "auth__learn_locations": "auth__learn_locations",
  "auth__confirm_email": "auth__confirm_email",
  "auth__confirm_email_later": "auth__confirm_email_later",
  "auth_error__unfortunately_vpn": "auth_error__unfortunately_vpn",
  "auth_error__unfortunately_vpn_2": "auth_error__unfortunately_vpn_2",
  "auth_error__email_required": "auth_error__email_required",
  "auth_error__captcha": "auth_error__captcha",
  "auth_error__email_in_use": "auth_error__email_in_use",
  "auth_error__denied": "auth_error__denied",
  "auth_error__refreshed": "auth_error__refreshed",
  "auth_error__blocked": "auth_error__blocked",
  "auth_notification__activate": "auth_notification__activate",
  "E-mail required": "E-mail required",
  "You should accept terms and privacy policy": "You should accept terms and privacy policy",
  "Password is empty": "Password is empty",
  "Invalid email format": "Invalid email format",
  "Should be at least 8 characters long": "Should be at least 8 characters long",
  "bet__bets": "bet__bets",
  "bet__slip": "bet__slip",
  "bet__parlay": "bet__parlay",
  "bet__pending": "bet__pending",
  "bet__complete": "bet__complete",
  "bet__canceled": "bet__canceled",
  "bet__no_bets": "bet__no_bets",
  "bet__history": "bet__history",
  "bet__full_time": "bet__full_time",
  "bet__regular_time": "bet__regular_time",
  "bet__1st_half": "bet__1st_half",
  "bet__1st_inning": "bet__1st_inning",
  "bet__1st_5_innings": "bet__1st_5_innings",
  "bet__1st_set": "bet__1st_set",
  "bet__2nd_set": "bet__2nd_set",
  "bet__3rd_set": "bet__3rd_set",
  "bet__4th_set": "bet__4th_set",
  "bet__5th_set": "bet__5th_set",
  "bet__1st_quarter": "bet__1st_quarter",
  "bet__2nd_quarter": "bet__2nd_quarter",
  "bet__3rd_quarter": "bet__3rd_quarter",
  "bet__4th_quarter": "bet__4th_quarter",
  "bet__place_bet": "bet__place_bet",
  "bet__placing_bet": "bet__placing_bet",
  "bet__place_no_risk": "bet__place_no_risk",
  "bet__amount": "bet__amount",
  "bet__max": "bet__max",
  "bet__accept_changes": "bet__accept_changes",
  "bet__check_transaction": "bet__check_transaction",
  "bet__not_enough_balance": "bet__not_enough_balance",
  "bet__refill_wallet": "bet__refill_wallet",
  "bet__no_odds_changed": "bet__no_odds_changed",
  "bet__accept_all": "bet__accept_all",
  "bet__accept_better": "bet__accept_better",
  "bet__total_stake": "bet__total_stake",
  "bet__total_win": "bet__total_win",
  "bet__winning": "bet__winning",
  "bet__done": "bet__done",
  "bet__crypro_equivalent": "bet__crypro_equivalent",
  "bet__note_approximations": "bet__note_approximations",
  "bet_notification__bet_win": "bet_notification__bet_win",
  "bet_notification__bet_win_2": "bet_notification__bet_win_2",
  "bet_notification__bet_lose": "bet_notification__bet_lose",
  "bet_notification__bet_lose_2": "bet_notification__bet_lose_2",
  "bet_notification__bet_refund": "bet_notification__bet_refund",
  "bet_notification__bet_refund_2": "bet_notification__bet_refund_2",
  "bet_notification__bet_cancelled": "bet_notification__bet_cancelled",
  "bet_notification__bet_cancelled_2": "bet_notification__bet_cancelled_2",
  "bet_notification__bet_unknown": "bet_notification__bet_unknown",
  "bet_notification__bet_unknown_2": "bet_notification__bet_unknown_2",
  "bet_notification__bets_rejected": "bet_notification__bets_rejected",
  "bet_notification__bets_and_more_rejected": "bet_notification__bets_and_more_rejected",
  "bet_notification__bets_count_rejected": "bet_notification__bets_count_rejected",
  "bet_notification__bet_rejected": "bet_notification__bet_rejected",
  "bet_notification__bet_rejected_2": "bet_notification__bet_rejected_2",
  "bet_notification__bet_rejected_3": "bet_notification__bet_rejected_3",
  "bet_notification__bet_rejected_4": "bet_notification__bet_rejected_4",
  "bet_notification__bet_parlay_status": "bet_notification__bet_parlay_status",
  "bet_notification__bet_parlay_status_2": "bet_notification__bet_parlay_status_2",
  "bet_notification__bet_completed": "bet_notification__bet_completed",
  "common__contact_us": "common__contact_us",
  "common__platform_description": "common__platform_description",
  "common__available": "common__available",
  "common__yes": "common__yes",
  "common__no": "common__no",
  "common__error": "common__error",
  "common__and": "common__and",
  "common__or": "common__or",
  "common__loading": "common__loading",
  "common__first": "common__first",
  "common__last": "common__last",
  "common__no_data": "common__no_data",
  "common__confirm": "common__confirm",
  "common__close": "common__close",
  "common__name": "common__name",
  "common__you": "common__you",
  "common__copied": "common__copied",
  "common__volume": "common__volume",
  "common__submit": "common__submit",
  "common__accept": "common__accept",
  "common__decline": "common__decline",
  "common__off": "common__off",
  "common__unsubscribe": "common__unsubscribe",
  "common__apply": "common__apply",
  "common__activate": "common__activate",
  "common__notifications": "common__notifications",
  "common__mark_all_read": "common__mark_all_read",
  "common__cannot_be_empty": "common__cannot_be_empty",
  "common__show_more": "common__show_more",
  "common__show_less": "common__show_less",
  "common__info": "common__info",
  "common__main": "common__main",
  "common__from": "common__from",
  "common__to": "common__to",
  "common__change": "common__change",
  "common__more_info": "common__more_info",
  "common__pending_confirm": "common__pending_confirm",
  "common__up_to": "common__up_to",
  "common__please": "common__please",
  "common_notification__welcome": "common_notification__welcome",
  "common_notification__learn_more": "common_notification__learn_more",
  "common_notification__no_recent": "common_notification__no_recent",
  "common__completing": "common__completing",
  "common__expiring": "common__expiring",
  "common__ok": "common__ok",
  "common__back": "common__back",
  "common__continue": "common__continue",
  "settings": "settings",
  "settings__language": "settings__language",
  "settings__odds_format": "settings__odds_format",
  "settings__currency": "settings__currency",
  "settings__edit": "settings__edit",
  "settings__in_fiat": "settings__in_fiat",
  "settings__choose_currency": "settings__choose_currency",
  "settings__two_factor_description": "settings__two_factor_description",
  "settings__enable_2FA": "settings__enable_2FA",
  "settings__2FA_authentication": "settings__2FA_authentication",
  "settings__sign_in": "settings__sign_in",
  "settings__set_new_password": "settings__set_new_password",
  "settings__security": "settings__security",
  "settings__recovery_code": "settings__recovery_code",
  "settings__type_recovery_code": "settings__type_recovery_code",
  "settings__deactivate_2FA": "settings__deactivate_2FA",
  "settings__confirm_2FA": "settings__confirm_2FA",
  "settings__deactivate": "settings__deactivate",
  "settings__scan_qr": "settings__scan_qr",
  "settings__2FA_Apps": "settings__2FA_Apps",
  "settings__error": "settings__error",
  "settings__error_wrong_2FA": "settings__error_wrong_2FA",
  "settings__use_tfa": "settings__use_tfa",
  "settings__disclaimer": "settings__disclaimer",
  "settings__use_recovery": "settings__use_recovery",
  "settings__show_recovery": "settings__show_recovery",
  "settings_change_email__password_verification": "settings_change_email__password_verification",
  "settings_change_email__submit_title": "settings_change_email__submit_title",
  "settings_change_email__email_verification": "settings_change_email__email_verification",
  "settings_change_email__send_code": "settings_change_email__send_code",
  "settings_change_email__enter_new_email": "settings_change_email__enter_new_email",
  "settings_change_email__verification_code": "settings_change_email__verification_code",
  "settings_change_email__didnt_recieve": "settings_change_email__didnt_recieve",
  "settings_change_email__verify": "settings_change_email__verify",
  "settings_change_email__message": "settings_change_email__message",
  "settings__wrong_recovery": "settings__wrong_recovery",
  "settings__resend_confirmation": "settings__resend_confirmation",
  "settings__sent_confirmation": "settings__sent_confirmation",
  "settings__recieve_newsletter": "settings__recieve_newsletter",
  "settings__view_in": "settings__view_in",
  "settings__hide_zero": "settings__hide_zero",
  "settings__verify_password": "settings__verify_password",
  "settings__verify_2fa": "settings__verify_2fa",
  "settings__type_2fa": "settings__type_2fa",
  "settings__on_site": "settings__on_site",
  "settings_error__code_required": "settings_error__code_required",
  "settings_error__wrong_2fa_format": "settings_error__wrong_2fa_format",
  "settings_notification__confirmation_sent": "settings_notification__confirmation_sent",
  "settings_notification__email_changed": "settings_notification__email_changed",
  "settings_notification__confirm_email": "settings_notification__confirm_email",
  "settings_notification__email_confirmed": "settings_notification__email_confirmed",
  "faq__title": "faq__title",
  "faq__what_is_sportbet": "faq__what_is_sportbet",
  "faq__sportbet": "faq__sportbet",
  "faq__what_is_eos": "faq__what_is_eos",
  "faq__eos": "faq__eos",
  "faq__how_to_create_eos_account": "faq__how_to_create_eos_account",
  "faq__account": "faq__account",
  "faq__my_bet_has_won": "faq__my_bet_has_won",
  "faq__payout": "faq__payout",
  "faq__why_i_cant_find_event": "faq__why_i_cant_find_event",
  "faq__no_event": "faq__no_event",
  "faq__can_i_cancel_bet": "faq__can_i_cancel_bet",
  "faq__bet_cancel": "faq__bet_cancel",
  "faq__is_possible_to_bet_on_esports_in_crypto": "faq__is_possible_to_bet_on_esports_in_crypto",
  "faq__is_scam_sportsbetting_site": "faq__is_scam_sportsbetting_site",
  "faq__is_scam": "faq__is_scam",
  "faq__is_safe_sports_betting_site": "faq__is_safe_sports_betting_site",
  "faq__is_safe": "faq__is_safe",
  "faq__bet_in_crypto": "faq__bet_in_crypto",
  "faq__how_to_make_deposits": "faq__how_to_make_deposits",
  "faq__how_to_make_deposits_text": "faq__how_to_make_deposits_text",
  "faq__eos_fork": "faq__eos_fork",
  "faq__eos_transaction_reversed": "faq__eos_transaction_reversed",
  "faq__terms_conditions": "faq__terms_conditions",
  "feedback__title": "feedback__title",
  "feedback__sent": "feedback__sent",
  "feedback__send": "feedback__send",
  "feedback_error__failed": "feedback_error__failed",
  "history": "history",
  "history__operation": "history__operation",
  "history__amount": "history__amount",
  "history__status": "history__status",
  "history__not_available": "history__not_available",
  "history__loading": "history__loading",
  "history__no_history": "history__no_history",
  "leaderboard": "leaderboard",
  "leaderboard__current_week_ends": "leaderboard__current_week_ends",
  "leaderboard__days": "leaderboard__days",
  "leaderboard__hours": "leaderboard__hours",
  "leaderboard__minutes": "leaderboard__minutes",
  "leaderboard__seconds": "leaderboard__seconds",
  "leaderboard__not_available": "leaderboard__not_available",
  "leaderboard__current_week": "leaderboard__current_week",
  "leaderboard__last_week": "leaderboard__last_week",
  "leaderboard__estimated_payout": "leaderboard__estimated_payout",
  "leaderboard__payout": "leaderboard__payout",
  "leaderboard__date_range": "leaderboard__date_range",
  "leaderboard__payout_pool": "leaderboard__payout_pool",
  "leaderboard__exchange_rates": "leaderboard__exchange_rates",
  "leaderboard__updated_at": "leaderboard__updated_at",
  "leaderboard__no_bets": "leaderboard__no_bets",
  "leaderboard__rank": "leaderboard__rank",
  "leaderboard__breakdown": "leaderboard__breakdown",
  "leaderboard__volume_breakdown": "leaderboard__volume_breakdown",
  "leaderboard__payout_breakdown": "leaderboard__payout_breakdown",
  "leaderboard__title": "leaderboard__title",
  "rules": "rules",
  "rules__integrity": "rules__integrity",
  "rules__integrity_1": "rules__integrity_1",
  "rules__integrity_2": "rules__integrity_2",
  "rules__integrity_3": "rules__integrity_3",
  "rules__integrity_4": "rules__integrity_4",
  "rules__privacy": "rules__privacy",
  "rules__general": "rules__general",
  "rules__general_1": "rules__general_1",
  "rules__general_2": "rules__general_2",
  "rules__general_3": "rules__general_3",
  "rules__general_4": "rules__general_4",
  "rules__general_5": "rules__general_5",
  "rules__general_6": "rules__general_6",
  "rules__general_7": "rules__general_7",
  "rules__general_8": "rules__general_8",
  "rules__general_9": "rules__general_9",
  "rules__bonus": "rules__bonus",
  "rules__bonus_1": "rules__bonus_1",
  "rules__bonus_2": "rules__bonus_2",
  "rules__bonus_3": "rules__bonus_3",
  "rules__bonus_4": "rules__bonus_4",
  "rules__bonus_5": "rules__bonus_5",
  "rules__bonus_6": "rules__bonus_6",
  "rules__bonus_7": "rules__bonus_7",
  "rules__bonus_8": "rules__bonus_8",
  "rules__bonus_9": "rules__bonus_9",
  "rules__bonus_10": "rules__bonus_10",
  "rules__bonus_11": "rules__bonus_11",
  "rules__bonus_12": "rules__bonus_12",
  "rules__bonus_13": "rules__bonus_13",
  "rules__bonus_14": "rules__bonus_14",
  "rules__bonus_15": "rules__bonus_15",
  "rules__bonus_16": "rules__bonus_16",
  "rules__bonus_17": "rules__bonus_17",
  "rules__bonus_18": "rules__bonus_18",
  "rules__bonus_19": "rules__bonus_19",
  "rules__bonus_20": "rules__bonus_20",
  "rules__bonus_21": "rules__bonus_21",
  "rules__bonus_22": "rules__bonus_22",
  "rules__sport": "rules__sport",
  "bonus": "bonus",
  "bonus__wager": "bonus__wager",
  "bonus__valid_until": "bonus__valid_until",
  "bonus__will_transferred": "bonus__will_transferred",
  "bonus__code": "bonus__code",
  "bonus__select": "bonus__select",
  "bonus__promotions": "bonus__promotions",
  "bonus__completed_at": "bonus__completed_at",
  "bonus__credited": "bonus__credited",
  "bonus__amount": "bonus__amount",
  "bonus__forfeit": "bonus__forfeit",
  "bonus__canceled_at": "bonus__canceled_at",
  "bonus__expires_at": "bonus__expires_at",
  "bonus__sum": "bonus__sum",
  "bonus__forfeit_confirmation": "bonus__forfeit_confirmation",
  "bonus__risk_free_credited": "bonus__risk_free_credited",
  "bonus__risk_free_credited_2": "bonus__risk_free_credited_2",
  "bonus__promoTerms_1": "bonus__promoTerms_1",
  "bonus__promoTerms_2": "bonus__promoTerms_2",
  "bonus__promoTerms_3": "bonus__promoTerms_3",
  "bonus__promoTerms_4": "bonus__promoTerms_4",
  "bonus__promoTerms_5": "bonus__promoTerms_5",
  "bonus_promo__deposit_play": "bonus_promo__deposit_play",
  "bonus_promo__cash_out": "bonus_promo__cash_out",
  "bonus_promo__win": "bonus_promo__win",
  "bonus_promo__min_deposit": "bonus_promo__min_deposit",
  "bonus_promo__max_bet": "bonus_promo__max_bet",
  "bonus_promo__max_bet_amount": "bonus_promo__max_bet_amount",
  "bonus_promo__rollover_sport": "bonus_promo__rollover_sport",
  "bonus_promo__rollover_casino": "bonus_promo__rollover_casino",
  "bonus_promo__in_crypto": "bonus_promo__in_crypto",
  "bonus_promo__welcome_bonus": "bonus_promo__welcome_bonus",
  "bonus_promo__refund": "bonus_promo__refund",
  "bonus_promo__internal_audit": "bonus_promo__internal_audit",
  "bonus_promo__higher_contribute": "bonus_promo__higher_contribute",
  "bonus_promo__all": "bonus_promo__all",
  "bonus_promo__without_bonus": "bonus_promo__without_bonus",
  "bonus_promo__without_wager": "bonus_promo__without_wager",
  "bonus__available_promo": "bonus__available_promo",
  "bonus__activate_bonus_code": "bonus__activate_bonus_code",
  "bonus_notification__bonus_canceled": "bonus_notification__bonus_canceled",
  "bonus_notification__bonus_activated": "bonus_notification__bonus_activated",
  "bonus_notification__bonus_deactivated": "bonus_notification__bonus_deactivated",
  "bonus_notification__bonus_available": "bonus_notification__bonus_available",
  "bonus_notification__no_bonus_available": "bonus_notification__no_bonus_available",
  "bonus_notification__no_deposit_bonus_credited": "bonus_notification__no_deposit_bonus_credited",
  "bonus_notification__have_bonus": "bonus_notification__have_bonus",
  "bonus_notification__activated": "bonus_notification__activated",
  "bonus_notification__activated_2": "bonus_notification__activated_2",
  "bonus_notification__canceled": "bonus_notification__canceled",
  "bonus_notification__credited_how_to_activate": "bonus_notification__credited_how_to_activate",
  "bonus_notification__choose_currency": "bonus_notification__choose_currency",
  "bonus_notification__min_amount": "bonus_notification__min_amount",
  "bonus_notification__check_out": "bonus_notification__check_out",
  "bonus_notification__terms": "bonus_notification__terms",
  "bonus_notification__terms_2": "bonus_notification__terms_2",
  "bonus_notification__gl": "bonus_notification__gl",
  "bonus_notification__session_expired": "bonus_notification__session_expired",
  "bonus_notification__bonus_code_activated": "bonus_notification__bonus_code_activated",
  "bonus_notification__loyal": "bonus_notification__loyal",
  "bonus_notification__loyal_2": "bonus_notification__loyal_2",
  "wechat__scan_qr": "wechat__scan_qr",
  "wechat__invitation": "wechat__invitation",
  "self_exclusion__important_message": "self_exclusion__important_message",
  "self_exclusion__period_prompt": "self_exclusion__period_prompt",
  "self_exclusion__period_set_message": "self_exclusion__period_set_message",
  "self_exclusion__appreciation_message": "self_exclusion__appreciation_message",
  "self_exclusion__cooldown_end": "self_exclusion__cooldown_end",
  "self__exclusion_email_instructions_sent": "self__exclusion_email_instructions_sent",
  "self__exclusion_exclude_email": "self__exclusion_exclude_email",
  "self__exlude": "self__exlude",
  "self__exclusion": "self__exclusion",
  "self__exclusion_start": "self__exclusion_start",
  "self__exclusion_request": "self__exclusion_request",
  "deposit": "deposit",
  "deposit__memo-required": "deposit__memo-required",
  "deposit-options__bonus-credited-message": "deposit-options__bonus-credited-message",
  "deposit-options__bonus-credited": "deposit-options__bonus-credited",
  "deposit-options__check": "deposit-options__check",
  "deposit-options__fiat-unavailable": "deposit-options__fiat-unavailable",
  "deposit-options__use-crypto": "deposit-options__use-crypto",
  "deposit-guide__need-help": "deposit-guide__need-help",
  "deposit-guide__how-to": "deposit-guide__how-to",
  "deposit-guide__message": "deposit-guide__message",
  "deposit_guide__after_confirmation": "deposit_guide__after_confirmation",
  "deposit_guide__less_1$": "deposit_guide__less_1$",
  "deposit_guide__delay": "deposit_guide__delay",
  "deposit_guide__min_deposit": "deposit_guide__min_deposit",
  "deposit_guide__fiat_warning": "deposit_guide__fiat_warning",
  "deposit_notification__deposit_now": "deposit_notification__deposit_now",
  "deposit__credit_card": "deposit__credit_card",
  "deposit__crypto_title": "deposit__crypto_title",
  "wallet": "wallet",
  "wallet__transfer": "wallet__transfer",
  "wallet__bonus-balance": "wallet__bonus-balance",
  "wallet__search-currency": "wallet__search-currency",
  "wallet__nothing-found": "wallet__nothing-found",
  "wallet__payment_details": "wallet__payment_details",
  "wallet__unavailable": "wallet__unavailable",
  "wallet__estimated": "wallet__estimated",
  "wallet__bonus_balance": "wallet__bonus_balance",
  "wallet__select_network": "wallet__select_network",
  "wallet__select_currency": "wallet__select_currency",
  "wallet__deposit_address": "wallet__deposit_address",
  "wallet__memo": "wallet__memo",
  "wallet__loading_addresses": "wallet__loading_addresses",
  "wallet__end_balance": "wallet__end_balance",
  "wallet_error__insufficient_founds": "wallet_error__insufficient_founds",
  "wallet_error__insufficient_founds_2": "wallet_error__insufficient_founds_2",
  "wallet_error__insufficient_founds_3": "wallet_error__insufficient_founds_3",
  "withdraw": "withdraw",
  "withdraw__amount": "withdraw__amount",
  "withdraw__memo-required": "withdraw__memo-required",
  "withdraw__processing": "withdraw__processing",
  "withdraw__fee": "withdraw__fee",
  "withdraw__amount_to_recieve": "withdraw__amount_to_recieve",
  "withdraw_error__invalid_amount": "withdraw_error__invalid_amount",
  "withdraw_error__equal_or_less": "withdraw_error__equal_or_less",
  "withdraw_error__invalid_address": "withdraw_error__invalid_address",
  "wallet_error__select_network": "wallet_error__select_network",
  "wallet__search_currency": "wallet__search_currency",
  "wallet__nothing_found": "wallet__nothing_found",
  "wallet__search_country": "wallet__search_country",
  "wallet__card_issued": "wallet__card_issued",
  "withdraw__memo_required": "withdraw__memo_required",
  "deposit__guide_need_help": "deposit__guide_need_help",
  "deposit__guide_howto": "deposit__guide_howto",
  "deposit__guide_message": "deposit__guide_message",
  "deposit__memo_required": "deposit__memo_required",
  "deposit__sum": "deposit__sum",
  "deposit__amount": "deposit__amount",
  "deposit__receive": "deposit__receive",
  "deposit__redirect": "deposit__redirect",
  "deposit__crypto": "deposit__crypto",
  "deposit__fiat_ineligible": "deposit__fiat_ineligible",
  "langing__anonymous": "langing__anonymous",
  "langing__anonymous_2": "langing__anonymous_2",
  "langing__thrill": "langing__thrill",
  "langing__experience_with": "langing__experience_with",
  "langing__forget_kyc": "langing__forget_kyc",
  "langing__instant_withdrawals": "langing__instant_withdrawals",
  "langing__players_welcome": "langing__players_welcome",
  "BET_PERIOD_FIRST": "BET_PERIOD_FIRST",
  "Become an Affiliate": "Become an Affiliate",
  "The line, odds or availability of your selections has changed.": "The line, odds or availability of your selections has changed.",
  "Sport suspended": "Sport suspended",
  "Bet not available": "Bet not available",
  "Bet suspended": "Bet suspended",
  "Multiple bets on the same event": "Multiple bets on the same event",
  "More bets than allowed in parlay": "More bets than allowed in parlay",
  "Odds have changed": "Odds have changed",
  "Bet amount should be between": "Bet amount should be between",
  "Maximum odd exceeded. Remove selections from betslip to decrease odds": "Maximum odd exceeded. Remove selections from betslip to decrease odds",
  "The line, odds or availability of your selections has changed": "The line, odds or availability of your selections has changed",
  "bets__parlay_multiple_restriction": "bets__parlay_multiple_restriction",
  "bets__parlay_amount_restriction": "bets__parlay_amount_restriction",
  "Max bet": "Max bet",
  "Min bet": "Min bet",
  "Risk exceeded": "Risk exceeded",
  "Risk exceeded for": "Risk exceeded for",
  "Cannot process withdrawal at the moment. Please, try later": "Cannot process withdrawal at the moment. Please, try later",
  "Placed": "Placed",
  "Pending": "Pending",
  "Lose": "Lose",
  "Lose Half": "Lose Half",
  "Refund": "Refund",
  "Win Half": "Win Half",
  "Win": "Win",
  "Deleted": "Deleted",
  "Cancelled": "Cancelled",
  "Rejected": "Rejected",
  "Skipped": "Skipped",
  "Decimal": "Decimal",
  "American": "American",
  "Handball": "Handball",
  "Account": "Account",
  "Support": "Support",
  "Unread": "Unread",
  "Inbox": "Inbox",
  "General": "General",
  "Saved": "Saved",
  "Deposit": "Deposit",
  "Withdraw": "Withdraw",
  "History": "History",
  "Transfer history": "Transfer history",
  "Transfer": "Transfer",
  "Wallet": "Wallet",
  "Done": "Done",
  "Game cannot be started": "Game cannot be started",
  "Credited": "Credited",
  "Canceled": "Canceled",
  "Completed": "Completed",
  "Pending Activation": "Pending Activation",
  "Active": "Active",
  "Expired": "Expired",
  "Pending Cancellation": "Pending Cancellation",
  "Current password is incorrect": "Current password is incorrect",
  "Copied": "Copied",
  "Wrong password": "Wrong password",
  "Code is not correct": "Code is not correct",
  "E-mail address is not registered": "E-mail address is not registered",
  "E-mail address already used": "E-mail address already used",
  "Logging In": "Logging In",
  "Logging Out": "Logging Out",
  "First Half": "First Half",
  "Max 64 characters": "Max 64 characters",
  "Email should not be empty": "Email should not be empty",
  "Feedback should not be empty": "Feedback should not be empty",
  "Preferencess": "Preferencess",
  "Community": "Community",
  "Popular Pages": "Popular Pages",
  "Information": "Information",
  "Contact Us": "Contact Us",
  "LIVE": "LIVE",
  "Upcoming": "Upcoming",
  "Europe": "Europe",
  "World": "World",
  "England": "England",
  "Germany": "Germany",
  "Spain": "Spain",
  "Italy": "Italy",
  "France": "France",
  "South America": "South America",
  "Japan": "Japan",
  "South Korea": "South Korea",
  "Netherlands": "Netherlands",
  "Portugal": "Portugal",
  "Turkey": "Turkey",
  "Saudi Arabia": "Saudi Arabia",
  "USA": "USA",
  "Asia": "Asia",
  "Brazil": "Brazil",
  "Australia": "Australia",
  "Argentina": "Argentina",
  "North America": "North America",
  "Algeria": "Algeria",
  "Austria": "Austria",
  "Belgium": "Belgium",
  "Bulgaria": "Bulgaria",
  "Chile": "Chile",
  "Colombia": "Colombia",
  "Costa Rica": "Costa Rica",
  "Croatia": "Croatia",
  "Cyprus": "Cyprus",
  "Czech Republic": "Czech Republic",
  "Denmark": "Denmark",
  "Ecuador": "Ecuador",
  "Egypt": "Egypt",
  "Greece": "Greece",
  "Hungary": "Hungary",
  "India": "India",
  "Indonesia": "Indonesia",
  "Israel": "Israel",
  "Jamaica": "Jamaica",
  "Jordan": "Jordan",
  "Latvia": "Latvia",
  "Malta": "Malta",
  "Mexico": "Mexico",
  "Northern Ireland": "Northern Ireland",
  "Norway": "Norway",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Poland": "Poland",
  "Qatar": "Qatar",
  "Rep. Ireland": "Rep. Ireland",
  "Romania": "Romania",
  "Scotland": "Scotland",
  "Serbia": "Serbia",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Sweden": "Sweden",
  "Switzerland": "Switzerland",
  "Ukraine": "Ukraine",
  "Uruguay": "Uruguay",
  "Wales": "Wales",
  "Venezuela": "Venezuela",
  "Vietnam": "Vietnam",
  "UAE": "UAE",
  "Tunisia": "Tunisia",
  "Thailand": "Thailand",
  "South Africa": "South Africa",
  "Singapore": "Singapore",
  "Russia": "Russia",
  "Finland": "Finland",
  "Estonia": "Estonia",
  "Moldova": "Moldova",
  "Uzbekistan": "Uzbekistan",
  "Iran": "Iran",
  "Palestine": "Palestine",
  "Oman": "Oman",
  "Kuwait": "Kuwait",
  "Morocco": "Morocco",
  "Malaysia": "Malaysia",
  "Lebanon": "Lebanon",
  "Kazakhstan": "Kazakhstan",
  "Hong Kong": "Hong Kong",
  "Georgia": "Georgia",
  "Bahrain": "Bahrain",
  "Syria": "Syria",
  "Pakistan": "Pakistan",
  "Monaco": "Monaco",
  "Luxembourg": "Luxembourg",
  "Lithuania": "Lithuania",
  "Liechtenstein": "Liechtenstein",
  "Fenerbahce": "Fenerbahce",
  "Galatasaray": "Galatasaray",
  "Paris": "Paris",
  "AFC Cup": "AFC Cup",
  "ATP CH Concepcion": "ATP CH Concepcion",
  "World Cup Qualification - Europe": "World Cup Qualification - Europe",
  "World Cup Qualification - Asia": "World Cup Qualification - Asia",
  "NBL": "NBL",
  "Mattoni NBL": "Mattoni NBL",
  "Euroleague Men": "Euroleague Men",
  "Korisliiga": "Korisliiga",
  "ATP Miami": "ATP Miami",
  "WTA Miami": "WTA Miami",
  "ATP Miami Doubles": "ATP Miami Doubles",
  "WTA Miami Doubles": "WTA Miami Doubles",
  "ATP US Open": "ATP US Open",
  "WTA US Open": "WTA US Open",
  "J League Cup": "J League Cup",
  "WTA Antalya 2": "WTA Antalya 2",
  "UEFA Champions League": "UEFA Champions League",
  "UEFA Europa League": "UEFA Europa League",
  "UEFA Conference League": "UEFA Conference League",
  "FIFA Club World Cup": "FIFA Club World Cup",
  "PGL Bucharest": "PGL Bucharest",
  "Soccer": "Soccer",
  "Baseball": "Baseball",
  "Basketball": "Basketball",
  "Boxing/MMA": "Boxing/MMA",
  "Esports": "Esports",
  "Hockey": "Hockey",
  "Cricket": "Cricket",
  "Football": "Football",
  "Tennis": "Tennis",
  "AFL": "AFL",
  "Rugby Union": "Rugby Union",
  "Rugby League": "Rugby League",
  "Volleyball": "Volleyball",
  "Table Tennis": "Table Tennis",
} as const;

export type ResourceKeys = keyof typeof Resources;