import { resendActivation } from "api/loginApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Resources } from "scripts/index.ts";

import { getLoginToken } from "selectors/loginSelectors";

import Button from "components/common/Button";
import { FormErrorMessage, FormSavedMessage } from "components/common/FormErrors";
import Popup from "components/common/Popup.tsx";

import letterImg from "../../../images/letter.svg";

const status = {
  sending: 1,
  success: 2,
  failure: 3,
};

const ActivateEmail = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const loginToken = useSelector(getLoginToken);
  const [formStatus, setStatus] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!loginToken) {
      history.replace(location.pathname);
    }
  }, [loginToken]);

  const resend = async () => {
    try {
      setError(null);
      setStatus(status.sending);
      await resendActivation(loginToken);
      setStatus(status.success);
    } catch (e) {
      setStatus(status.failure);
      setError("Please, try again later");
    }
  };

  return (
    <Popup
      className={"activate-email-popup"}
      onClose={() => {
        history.replace(location.pathname);
      }}
    >
      <div className={"login-form"} style={{ marginTop: 0, textAlign: "center" }}>
        <div className={"login-message"}>
          <img src={letterImg} style={{ margin: "0 auto 18px", display: "block" }} />
          <p>{t(Resources.auth__confirm_email)}</p>
        </div>

        <Button
          secondary
          className={"btn-submit btn-resend-confirmation"}
          style={{ marginBottom: 16 }}
          onClick={resend}
          loading={formStatus === status.sending}
        >
          {t(Resources.settings__resend_confirmation)}
        </Button>
        {formStatus === status.failure && error ? (
          <FormErrorMessage text={t(error)} style={{ justifyContent: "center" }} />
        ) : null}
        {formStatus === status.success ? (
          <FormSavedMessage
            text={t(Resources.settings_notification__confirmation_sent)}
            style={{ justifyContent: "center" }}
          />
        ) : null}
        <a
          href={"#"}
          style={{ display: "block", margin: "16px 0 0" }}
          onClick={(e) => {
            e.preventDefault();
            history.replace(location.pathname);
          }}
        >
          {t(Resources.auth__confirm_email_later)}
        </a>
      </div>
    </Popup>
  );
};

export default ActivateEmail;
