import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Resources } from "scripts/index.ts";

import { getUserTfaConfirm } from "selectors/userSelectors";

import { logout } from "actions/loginActions.ts";
import {
  changeTfaConfirmCode,
  toggleTfaConfirmRecovery,
  unlockTfaConfirm,
} from "actions/tfaConfirmActions";

import Button from "components/common/Button";
import Input from "components/common/Input.tsx";
import Popup from "components/common/Popup.tsx";

import { loadUserStats } from "../../../actions/userStatsActions";

const TfaConfirm = () => {
  const { t } = useTranslation();
  const [codeError, setCodeError] = useState(null);
  const history = useHistory();
  const { locked, code, recovery, loading, error } = useSelector(getUserTfaConfirm);
  const dispatch = useDispatch();

  const toggleForm = useCallback(
    (e) => {
      e.preventDefault();
      setCodeError(null);
      dispatch(toggleTfaConfirmRecovery());
    },
    [dispatch, setCodeError]
  );

  const changeCode = useCallback(
    (value) => {
      setCodeError(null);
      dispatch(changeTfaConfirmCode(value));
    },
    [dispatch]
  );

  const confirmTfa = useCallback(
    (e) => {
      e.preventDefault();
      if (!code) {
        setCodeError(Resources.settings_error__code_required);
        return;
      }
      if (!recovery && !/\d{6}/.test(code)) {
        setCodeError(Resources.settings_error__wrong_2fa_format);
        return;
      }
      setCodeError(null);
      dispatch(unlockTfaConfirm(history));
      dispatch(loadUserStats());
    },
    [code, dispatch]
  );

  if (!locked) return null;

  return (
    <Popup
      title={t(Resources.settings__2FA_authentication)}
      onClose={() => {
        dispatch(logout());
      }}
    >
      <div className={"tfa-settings__form"} style={{ maxWidth: 300, margin: "0 auto" }}>
        <form onSubmit={confirmTfa}>
          <Input
            type={recovery ? "text" : "number"}
            value={code}
            placeholder={
              recovery
                ? t(Resources.settings__type_recovery_code)
                : t(Resources.settings__type_2fa)
            }
            onChange={changeCode}
            error={
              codeError
                ? t(codeError)
                : error
                ? t(Resources.settings__error_wrong_2FA)
                : undefined
            }
            autoFocus
          />
          {/* error ? <FormErrorMessage text={t("Wrong TFA code")} style={{ margin: "0 0 12px" }} /> : null */}
          <div className={"settings__form-footer"}>
            <Button type={"submit"} disabled={loading} secondary className={"btn-main"}>
              {loading ? (
                <FontAwesomeIcon icon={faCircleNotch} spin />
              ) : recovery ? (
                t(Resources.settings__deactivate_2FA)
              ) : (
                t(Resources.settings__confirm_2FA)
              )}
            </Button>
          </div>
          <a href={"#"} className={"settings__tfa-recovery-link"} onClick={toggleForm}>
            {!recovery
              ? t(Resources.settings__use_recovery)
              : t(Resources.settings__use_tfa)}
          </a>
        </form>
      </div>
    </Popup>
  );
};

export default TfaConfirm;
